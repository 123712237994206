import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";


export const updateProgress = createAsyncThunk(
  "updateProgress",
  async (data, { rejectWithValue }) => {
    try {    
      const token = localStorage.getItem("token");

      const res = await api.post(
        `${API_URL}/tracking/update-progress`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );     
      return {
        status: res?.status,
        data: res?.data?.data,
        token: res?.data?.token,
      };
    } catch (error) {
      return rejectWithValue({
        message: error?.response?.data?.error || "Something went wrong",
        status: error?.response?.status || 500,
      });
    }
  }
);
