import React, { useState } from "react";
import "./WinningProcess.css";

const WinningProcess = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "LOREM IPSUM 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
      title: "LOREM IPSUM 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
      title: "LOREM IPSUM 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
      title: "LOREM IPSUM 4",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
      title: "LOREM IPSUM 5",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
      title: "LOREM IPSUM 6",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
    },
    {
        title: "LOREM IPSUM 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      },
      {
        title: "LOREM IPSUM 2",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      },
      {
        title: "LOREM IPSUM 3",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      },
      {
        title: "LOREM IPSUM 4",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      },
      {
        title: "LOREM IPSUM 5",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      },
      {
        title: "LOREM IPSUM 6",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      
      },
  ];


  // Total slides are grouped into sets of 3
  const cardsPerSlide = 3;
  const totalSlides = Math.ceil(slides.length / cardsPerSlide);

  const handleLeftArrowClick = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const handleRightArrowClick = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  return (
    <>
  
    <div className="WinningProcess-main-container">

      <div className="TrustedAgency-main-container-button-and-text2" >
        <div className="TrustedAgency-main-container-button-and-text-heading2">
          <h2>
            Our <span className="dificult-span">Winning </span> Process
          </h2>
          <div className="hiring-skainet-team-para">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod{" "}
            </p>
            <p>tempor incididunt ut labore et dolore magna aliquaenim</p>
          </div>
        </div>
        <div className="arrow-left-right">
          <img
            src="/Images/Winning/leftarr.svg"
            alt="Left Arrow"
            onClick={handleLeftArrowClick}
          />
          <img
            src="/Images/Winning/rightarr.svg"
            alt="Right Arrow"
            onClick={handleRightArrowClick}
          />
        </div>
      </div>

      {/* Slider content */}
      <div className="slider-container">
        <div
          className="slider-content"
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {Array.from({ length: totalSlides }).map((_, index) => (
            <div key={index} className="slide-group">
              {slides
                .slice(index * cardsPerSlide, index * cardsPerSlide + cardsPerSlide)
                .map((slide, idx) => (
                  <div key={idx} className="slide-card">
                    <h3 className="slide-title">{slide.title}</h3>
                    <p className="slide-description">{slide.description}</p>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
        <div className="winning-line-image">
       <div className='new-try-for-border----------botom'></div>
        <div className="line-dot-try-best-method">
            <img src="/Images/Winning/linedot.svg"/>
            <img src="/Images/Winning/linedot.svg"/>
            <img src="/Images/Winning/linedot.svg"/>
            <img src="/Images/Winning/linedot.svg"/>
            <img src="/Images/Winning/linedot.svg"/>
        </div>

        </div>
        
        <div className="bottom-cards-winning-main">
  <div className="bottom-card">
    <h3>LOREM IPSUM 1</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
  <div className="bottom-card">
    <h3>LOREM IPSUM 2</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
</div>

    </div>
    
    </>
  );
};

export default WinningProcess;
