import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTwitterCourses } from "../middleware/getTwitterCourses";

const initialState = {
  loading: false,
  error: "",
  getTwitterCoursessData : [],
};
const getTwitterCoursesSlice = createSlice({
  name: "getTwitterCourses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTwitterCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTwitterCourses.fulfilled, (state, action) => {
      state.loading = false;
      
      state.getTwitterCoursessData = action.payload.data?.courses || [];
    });
    builder.addCase(getTwitterCourses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getTwitterCoursesSlice.reducer;