import React, { useState, useRef, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom"; // Import useLocation
import "./ContentLibrary1.css";
import Dropdown from "react-bootstrap/Dropdown";
import ContentLibraryDetails from "../../Components/ContentLibraryDetails/ContentLibraryDetails";
import BottomDrawer from "../../Components/bottomdrawer/bottomdrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFolderContent } from "../../services/redux/middleware/getFolderContent";
import { getDownloaded } from "../../services/redux/middleware/getDownloaded";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import ClipLoader from "react-spinners/ClipLoader";
const ContentLibrary1 = () => {
  const location = useLocation();
  const masteryName = location.state?.masteryName || "Default Mastery";
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const videoRefs = useRef([]);
  const [visibleVideos, setVisibleVideos] = useState({});

  const handlePlayVideo = (index) => {

    setVisibleVideos((prev) => ({ ...prev, [index]: true }));
    const video = videoRefs.current[index];
    if (video) {
      video.play();
    }
  };
  const [selectedId, setSelectedId] = useState(null);
  const handleDetailsClick = (id) => {
    console.log("Id are", id)
    if (isMobile) {
      openDrawer();
    }
    setIsDetailsVisible(true);
    setSelectedId(id)
  };


  const closeDetails = () => {
    setIsDetailsVisible(false);
  };

  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    setOpen(true);
  };


  const { folder } = useParams();

  const dispatch = useDispatch();

  const [folderContent, setFolderContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const FolderDataContent = useSelector(
    (state) => state?.getFolderContent);

  useEffect(() => {
    const fetchFolderContent = async () => {
      setIsLoading(true);
      await dispatch(getFolderContent(folder));
      setIsLoading(false);
    };

    fetchFolderContent();
  }, [folder, dispatch]);

  useEffect(() => {
    setFolderContent(FolderDataContent);
  }, [FolderDataContent]);



  console.log("folderContent", folderContent?.profile?.data?.contents)


  function formatDate(dateString) {
    if (!dateString) return '';

    const date = new Date(dateString);

    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedTime = `${hours}${minutes > 0 ? `:${String(minutes).padStart(2, '0')}` : ''} ${ampm}`;


    return `${formattedDate} - ${formattedTime}`;
  }

  function handleDownload(fileUrl) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop();
    link.target = "_blank";

    link.click();
  }
  const filterDownloadContent = useSelector(
    (state) => state?.getFolderContent);


  const fetchgetDownloaded = async () => {
    setIsLoading(true)
    await dispatch(getDownloaded());
    setFolderContent(filterDownloadContent)
    setIsLoading(false)
    console.log("Download", filterDownloadContent?.profile?.data?.contents)
  };

  const videoRef = useRef(null);

  const [isVideoLoading, setIsVideoLoading] = useState({});

  const handleCanPlay = (index) => {
    setIsVideoLoading((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleWaiting = (index) => {
    setIsVideoLoading((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };


  const [videoStates, setVideoStates] = useState({});
  const togglePlayPause = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoStates[index]?.isPlaying) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
  };




  const [isHovered, setIsHovered] = useState({});


  const handleTimeUpdate = () => {
    if (videoRef.current) {
      // Access the video properties here safely
      console.log(videoRef.current.currentTime);
    }
  };


  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="chkingdiv">
          <>
            <div className="Contentc">
              <div className="ContentLibrary1">
                <div className="ContentLibrary1_div1">
                  <p className="ContentLibrary1_div1_p">
                    <span className="ContentLibrary1_div1_p1">
                      Content Library
                    </span>
                    <span className="ContentLibrary1_div1_p1span1"> &gt;</span>
                    <span className="ContentLibrary1_div1_p1span2">
                      {" "}{folderContent?.profile?.data?.folderName}

                    </span>
                    <span className="ContentLibrary1_div1_p1span1"> &gt;</span>
                    <span className="ContentLibrary1_div1_p1span3">{" "}{folderContent?.profile?.data?.contents?.length} Files</span>

                  </p>
                  <Dropdown className="dddrop">
                    <Dropdown.Toggle
                      className="dropdownmain"
                      variant="success"
                      style={{
                        backgroundColor: "#393C43",
                        borderColor: "#393C43",
                        color: "white",
                      }}
                    >
                      <img src="/Images/ContentLibraryImg/filter.svg" alt="" />
                      <p className="dropdownmain1ptag"> Filter</p>
                      <img
                        src="/Images/ContentLibraryImg/dropdownicon.svg"
                        alt=""
                      />{" "}
                      {/* <img src={arrowIcon} alt="arrow" style={{ width: '16px', height: '16px', marginLeft: '8px' }} /> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dddropppppp"
                      style={{ backgroundColor: "#393C43", padding: "20px" }}
                    >
                      <Dropdown.Item
                        href="#/action-1"
                        style={{
                          color: "white",
                          marginBottom: "15px",
                          padding: "0px",
                        }}
                        className="DropDownmenuOptionA"
                      >
                        Most Recent
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={fetchgetDownloaded}
                        style={{ color: "white", padding: "0px" }}
                        className="DropDownmenuOption"
                      >
                        Most Downloaded
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="containerr">
                {folderContent?.profile?.data?.contents?.length > 0 ? (
                  folderContent?.profile?.data?.contents?.map((folder, index) => (
                    <div key={index} className="itemm">
                      {folder.fileType === "image/jpeg" ? (
                        <img src={folder.image} alt="Item" className="image" />
                      ) : (

                        <div
                          className="videowrapper1"
                          style={{
                            position: "relative",
                          }}
                          onMouseEnter={() => setIsHovered((prev) => ({ ...prev, [index]: true }))}
                          onMouseLeave={() => setIsHovered((prev) => ({ ...prev, [index]: false }))}
                        >
                          {isVideoLoading[index] && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 2,
                                cursor: "pointer",
                              }}
                            >
                              <ClipLoader color="#0F6FFF" size={50} />
                            </div>
                          )}

                          <video
                            ref={(el) => (videoRefs.current[index] = el)}
                            src={folder.image}
                            className="video-element2"
                            onTimeUpdate={handleTimeUpdate}
                            onCanPlay={() => handleCanPlay(index)}
                            onWaiting={() => handleWaiting(index)}
                            controls={false}
                            playsInline
                            onPlay={() =>
                              setVideoStates((prevState) => ({
                                ...prevState,
                                [index]: { isPlaying: true },
                              }))
                            }
                            onPause={() =>
                              setVideoStates((prevState) => ({
                                ...prevState,
                                [index]: { isPlaying: false },
                              }))
                            }
                          ></video>

                          {(!videoStates[index]?.isPlaying || isHovered[index]) && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 2,
                                cursor: "pointer",
                              }}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: "0px",
                                }}
                                onClick={() => togglePlayPause(index)}
                              >
                                <img
                                  src={
                                    videoStates[index]?.isPlaying
                                      ? "/Images/ContentLibraryImg/newpause.svg"
                                      : "/Images/ContentLibraryImg/newplay.svg"
                                  }
                                  alt="Play/Pause"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                  }}
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      )}


                      <div className="ContentLibraryCards_div2">
                        <p className="ContentLibraryCards_div2_ptag">{folder?.title?.length > 25
                          ? folder.title.substring(0, 25) + "..."
                          : folder?.title}
                        </p>
                        <Dropdown className="ddddrop">
                          <Dropdown.Toggle
                            className="dropdownmain11"
                            variant="success"
                            style={{
                              backgroundColor: "#393C43",
                              borderColor: "#393C43",
                              color: "white",
                            }}
                          >
                            <img
                              className="deleteiconsss"
                              src="/Images/ContentLibraryImg/3dotimg.svg"
                              alt=""
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dddropppppp"
                            style={{
                              backgroundColor: "#24262B",
                              padding: "20px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <Dropdown.Item
                              href="#/action-1"
                              style={{
                                color: "white",
                                marginBottom: "15px",
                                padding: "0px",
                              }}
                              className="DropDownmenuOptionDivvvvv"
                              onClick={() => handleDownload(folder.image)}
                            >
                              <img src="/Images/ContentLibraryImg/downloadicon.svg" alt="" />
                              <p className="DropDownmenuOption">Download</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              style={{ color: "white", padding: "0px" }}
                              className="DropDownmenuOptionDivvvvv"
                              onClick={() => handleDetailsClick(folder._id)}
                            >
                              <img src="/Images/ContentLibraryImg/detailicon.svg" alt="" />
                              <p className="DropDownmenuOption">Details</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <p className="ContentLibraryCards_p">{folder?.description?.length > 45
                        ? folder.description.substring(0, 45) + "..."
                        : folder?.description}
                      </p>
                      <div className="ContentLibraryCards_lastdiv">
                        <img src="/Images/ContentLibraryImg/clock.svg" alt="" />
                        <p className="ContentLibraryCards_lastdivPtag">
                          {formatDate(folder?.createdAt)}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty-state">
                    <p className="empty-message">No Content Data Available</p>
                  </div>
                )}

              </div>


            </div>
            {isDetailsVisible && (
              <>
                <div className="details-overlay">
                  <ContentLibraryDetails closeDetails={closeDetails} id={selectedId} />
                </div>
                <BottomDrawer open={open} setOpen={setOpen} closeDetails={closeDetails} id={selectedId} />
              </>
            )}
          </>
        </div>)}
    </>
  );
};
export default ContentLibrary1;