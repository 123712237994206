import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import SakinetLogo from '../../assets/logo.svg';
import "./navbar.css";

const pages = ["Reviews", "Simple Process", "Our Offer", "Services"];
const settings = ["Reviews", "Simple Process", "Our Offer", "Services"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
    position="static"
    className="appbar__main"
    sx={{
      height: "80px",
      background:'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))',
      boxShadow: "none",
    }}
  >
  
      <Container className="navbar__header___main__div">
        <Toolbar disableGutters>
          {/* big screen logo */}
          <div className="navbar__main__div">
            <Box>
              <img className="logo__image__header" src={SakinetLogo} alt="logo" />
            </Box>
            <Box
              className="navbar__items__container"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              {pages.map((page) => (
                <Button
                  className="items__navbar__text"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box
              className="buttons__navbar_container"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <button className="register__navbar__button">Start Now</button>
            </Box>
          </div>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                minHeight:"100%",
                paddingTop:"0px",
                paddingBottom:"0px",
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  padding: 0, 
                  background:"black",
                },
              }}
            >
              {/* <Box
              className="buttons__navbar_container"
              sx={{ display: "flex" ,}}
            > */}
              <button className="register__navbar">Start Now</button>
            {/* </Box> */}
              {pages.map((page) => (
                 <MenuItem
                 key={page}
                 onClick={handleCloseNavMenu}
                 sx={{
                  paddingTop:"5px",
                  paddingBottom:"5px",
                   color: "white",
                   py: 0, 
                   "&:hover": {
                    background:"black",
                     color: "#0F6FFF", // Ensure text remains white on hover
                   },
                 }}
               >
                  <Typography sx={{ width:"100%",textAlign: "center", fontFamily:"Montserrat", fontWeight:"400",fontSize:"18px", lineHeight:"21.94px"}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
