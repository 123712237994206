import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getFolderContentDetail = createAsyncThunk(
  "getFolderContentDetail", 
  async ({ folderId, contentId }) => {  // Destructure here
    try {
      console.log("getFolderContentDetail", folderId, contentId);
      const res = await api.get(`${API_URL}/content/content-details/${folderId}/${contentId}`);
      console.log("Response", res);
      return res;
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
