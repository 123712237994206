import React, { useEffect, useState } from "react";
import "./SignInSkainet.css";
import { ToastContainer, toast } from 'react-toastify';
import { login } from "../../services/redux/middleware/login";
import { useDispatch } from "react-redux";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useNavigate } from "react-router-dom";
const SignInSkainet = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/Instagramcompont");
    } else {
      setIsCheckingToken(false);
    }
  }, [navigate]);

  if (isCheckingToken || isLoading) {
    return <ScreenLoader />;
  }

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error("Enter all text fields");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    const data = { email, password }
    console.log(data);


    try {
      const result = await dispatch(login(data));

      console.log(data);
      console.log(result);

      if (result?.payload?.status === 201) {
        setIsLoading(false);

        localStorage.setItem('UserEmail', email)
        localStorage.setItem('token', JSON.stringify(result?.payload?.token));

        navigate('/Instagramcompont');     
        setTimeout(() => {
          toast.success("Login Successfully");
        }, 300);

      } else if (result?.payload?.status === 404) {
        setIsLoading(false);
        setTimeout(() => {
          toast.error("No user Found");
        }, 100)
      } else {
        setIsLoading(false);
        setTimeout(() => {
          toast.error("Incorrect Password");
        }, 100)
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (

    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMainNext">
          <img className="SignUpImgNext" src="/Images/SignupImg/hero.png" alt="" />
          <div className="SignUpMain11Next">
            <div className="SignUpMain1Next">
              <div className="SignUpMain1_1Next">
                <img className="SignUpMain1_1iconNext" src="/Images/SignupImg/icon.svg" alt="...." />
                <p className="SignUpMain1_1ptagNext">Welcome Back!</p>
              </div>

              <div className="SignUpMain1_2Next">

                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Email Address</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon" />
                    <input
                      type="text"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <p className="Forgot-Password" onClick={() => { navigate('/ResetPasswordEmail') }}>Forgot Password?</p>
              <button className="SignUpMain1_2BtnNext" onClick={handleLogin}>Sign In</button>
              <p className="alreadysignup">Don’t have an account already?<span className="alreadysignup2" onClick={() => navigate("/SignUp")}> Sign up</span></p>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />

        </div>)}</>
  );
};

export default SignInSkainet;
