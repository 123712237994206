import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFolderContent } from "../middleware/getFolderContent";

const initialState = {
  loading: false,
  error: "",
  getFolderContent: [],
};
const getFolderContentSlice = createSlice({
  name: "getFolderContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFolderContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFolderContent.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(getFolderContent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getFolderContentSlice.reducer;