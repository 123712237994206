import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContentLibrary.css";
import { getFolder } from "../../services/redux/middleware/getFolder";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

const ContentLibrary = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const FolderData = useSelector((state) => state?.getFolder);

  useEffect(() => {
    const fetchFolder = async () => {
      setIsLoading(true);
      await dispatch(getFolder());
      setIsLoading(false);
    };
    fetchFolder();
  }, [dispatch]);

  const handleFolderContent = (folder) => {
    console.log("Folder Id:", folder);
    navigate(`/content-library1/${folder}`);
  };


  const folderData = FolderData?.profile?.data?.contentLibrary || [];

  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="Content">
          <div className="ContentLibraryTop">
            {folderData.map((folder, index) => (
              <div
                key={index}
                className="ContentLibraryTop_Div1"
                tabIndex="0"
                onClick={() => handleFolderContent(folder?._id)}
              >
                <img src="/Images/ContentLibraryImg/file.svg" alt="files" />
                <div className="ContentLibraryTop_Div2">
                  <p className="ContentLibraryTop_Div2_p1">
                    {folder?.folderName}
                  </p>
                  <p className="ContentLibraryTop_Div2_p2">
                    {folder?.contents?.length}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentLibrary;
