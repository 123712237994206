import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCourse } from "../middleware/getCourse";


const initialState = {
  loading: false,
  error: "",
  getCourse: {}, 
};
const getCourseSlice = createSlice({
  name: "getCourse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Data received in slice:", action.payload.data);
        
        state.getCourse = action.payload.data || {};
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || "Something went wrong";
        console.error("Error in getCourse slice:", action.error);
      });
  },
});

export default getCourseSlice.reducer; 
