import React from "react";
import "./NewFooter.css";
import Logo from "../../assets/logo.svg";
const NewFooter = () => {
    return(
<div className="Footer">
<div className="Footer1">
<div class="Footer1-content">
    <div className="LogoFooter">
        <img  className="logo" src={Logo} alt="Logo" />
        <p className="FooterParaLogo">Month to Month Payments, No <br className="break"/>Setup Fee, Pre-Trained Virtual <br className="break
        "/> Assistants</p>
        
    </div>

    <div className="FooTERRight">
        <div className="footerRightCol">
            <p className="RightColHead">LINKS</p>
            <div className="rightColParas">
                <a className="ParaFooter1">Looker Reports</a>
                <a className="ParaFooter1">Case Studies</a>
                <a className="ParaFooter1">Blog</a>
            </div>

        </div>
        <div className="footerRightCol">
            <p className="RightColHead">COMPANY</p>
            <div className="rightColParas">
                <a className="ParaFooter1">Services</a>
                <a className="ParaFooter1">Resource</a>
                <a className="ParaFooter1">About</a>
                <a className="ParaFooter1">Contact</a>
            </div>

        </div>
        <div className="RightColLast">
            <p className="sakinetfooter">Skainet Academy<br/> address goes here</p>
            <p className="FooterNumber">Tel: +44 000 000000</p>
            <p className="FooterNumber">team@skainetacademy.com</p>

        </div>
        
    </div>
    </div>
</div>
<div className="Footer2">
    <p className="copyRight">Copyright 2024 © Skainet Academy</p>
    <div class="social-links">
  <a href="#" class="social-link">Insta</a>
  <span class="separator">·</span>
  <a href="#" class="social-link">FaceBook</a>
  <span class="separator">·</span>
  <a href="#" class="social-link">Twitter</a>
  <span class="separator">·</span>
  <a href="#" class="social-link">LinkedIn</a>
  <span class="separator">·</span>
  <a href="#" class="social-link">Discord</a>
</div>


</div>

</div>
    );
 }
 export default NewFooter;