import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTwitterCourses } from "../../services/redux/middleware/getTwitterCourses";
import ScreenLoader from '../../Components/ScreenLoader/ScreenLoader';
import { useNavigate } from "react-router-dom";

function Twitter() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const twitterCoursesData = useSelector(
    (state) => state?.getTwitterCourses?.getTwitterCoursessData
  );

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      await dispatch(getTwitterCourses());
      setIsLoading(false);
    };

    fetchCourses();
  }, [dispatch]);

  if (isLoading) {
    return <ScreenLoader />;
  }
  const handleCourseClick = (id) => {
    console.log("id is", id)
    navigate(`/courseoverview/${id}`);
  }
  console.log(twitterCoursesData?._id);



  const formatDurationNew = (totalSeconds) => {
    totalSeconds = Math.floor(totalSeconds); // Ensure totalSeconds is an integer
    const totalMinutes = Math.floor(totalSeconds / 60); // Convert seconds to minutes
    const hours = Math.floor(totalMinutes / 60);        // Calculate hours
    const minutes = totalMinutes % 60;                 // Calculate remaining minutes
    const seconds = totalSeconds % 60;                 // Calculate remaining seconds

    // Format the duration string
    if (hours > 0) {
      return `${hours}h ${minutes}min`;
    } else if (minutes > 0) {
      return `${minutes}min ${seconds}s`;
    } else {
      return `${seconds}sec`;
    }
  };




  return (
    <div className='Cards___Twiter__alignmnt_Container'>
      {twitterCoursesData?.length > 0 ? (
        twitterCoursesData.map((course, index) => (
          <div key={index} className='card_img_container' onClick={() => handleCourseClick(course?._id)}>
            <img className='img-alignmnt' src={course.imgSrc} alt={course.title} />
            <div className='card_container_twiter'>
              <div className='Twiter_mastry____container'>
                <h2 className='twiter_Text__mastry'>

                  {course.title.length > 18 ? course.title.slice(0, 15) + '...' : course.title}
                </h2>
                <h2 className='dollor_text'>{course.price}</h2>
              </div>
              <div className='timer_container'>
                <div className='timer__containr'>
                  <img src='/Images/DrawerImages/twiter/time.svg' alt="Time" />
                  <p className='time_text'> {formatDurationNew(
                    course?.sections?.reduce((total, section) =>
                      total + section?.subSections?.reduce((subTotal, subsection) => subTotal + (subsection?.duration || 0), 0),
                      0
                    )
                  )}</p>
                </div>
                <div className='timer__containr'>
                  <img src='/Images/DrawerImages/twiter/chapter.svg' alt="Chapters" />
                  <p className='time_text'>  {course?.sections?.reduce((total, section) =>
                    total + (section?.subSections?.length || 0), 0)
                  } lessons</p>
                </div>
                <div className='timer__containr'>
                  <img src='/Images/DrawerImages/twiter/star.svg' alt="Rating" />
                  <p className='time_text'>{course.rating}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="NoCourse">No Courses Available</p>
      )}
    </div>
  );
}

export default Twitter;
