import React, { useEffect, useState } from "react";
import "./ContentLibraryDetails.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFolderContentDetail } from "../../services/redux/middleware/getFolderContentDetail";
import { toast } from "react-toastify";
const ContentLibraryDetails = ({ closeDetails, id }) => {




  const dispatch = useDispatch();
  const { folder } = useParams();
  console.log("folder", folder)
  console.log("id", id)

  const detailData = useSelector((state) => state?.getFolderContentDetail);

  useEffect(() => {
    const folderId = folder;
    const contentId = id;
    dispatch(getFolderContentDetail({ folderId, contentId }));
  }, [dispatch, folder, id]);
  console.log("Detail:", detailData?.getFolderContentDetail)


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';
    return `${day}${suffix} ${month} ${year} - ${hours}:${minutes}`;
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  function formatSize(sizeInBytes) {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${Math.ceil(sizeInMB)}mb`;
  }

  function handleDownload(fileUrl, fileType) {
    const link = document.createElement("a");  
    link.href = fileUrl;  
    link.download = fileUrl.split("/").pop();    
    link.target = "_blank";

    link.click();
  }
  
  
  return (
    <div className="ContentLibraryDetails_main">
      <div className="ContentLibraryDetails_main1">
        <div className="ContentLibraryDetails_main1_1">
          <img src="/Images/detailss/detail1.svg" alt=".." />
          <p className="ContentLibraryDetails_main1_1_ptag">{detailData?.getFolderContentDetail?.title}</p>
        </div>
        <img

          onClick={closeDetails}
          src="/Images/detailss/cross.svg"
          alt=".."
        />
      </div>

      {detailData?.getFolderContentDetail?.fileType === "video/mp4" ? (
        <video
          controls
          style={{ borderRadius: "10px" }}
          width="100%"
          height="240px"
        >
          <source
            src={detailData?.getFolderContentDetail?.image}
            type="video/mp4"
          />
          .
        </video>
      ) : (
        <img
          src={detailData?.getFolderContentDetail?.image}
          style={{ borderRadius: "10px", height: "240px", width: "100%" }}
          alt=".."
        />
      )}

      <button className="ContentLibraryDetails_main_btn"
      onClick={() => handleDownload(detailData?.getFolderContentDetail?.image, detailData?.getFolderContentDetail?.fileType)}
      >
        Download Asset
        <img src="/Images/detailss/downloadicon.svg" alt=".." />
      </button>

      <div className="ContentLibraryDetails_mainDiv2">
        <p className="ContentLibraryDetails_mainDiv2ptag">File Details</p>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Type</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">{detailData?.getFolderContentDetail?.fileType}</p>
        </div>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Size</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">{formatSize(detailData?.getFolderContentDetail?.fileSize)}</p>
        </div>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Updated</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">
            {formatDate(detailData?.getFolderContentDetail?.updatedAt)}
          </p>
        </div>
      </div>

      <p className="ContentLibraryDetails_main___p">Choose captions</p>

      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption1}
        </p>
        <img
          src="/Images/detailss/copyicon.svg"
          alt=".."
          onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption1)}
          style={{ cursor: 'pointer' }}
        />
      </div>

      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption2}
        </p>
        <img
          src="/Images/detailss/copyicon.svg"
          alt=".."
          onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption2)}
          style={{ cursor: 'pointer' }}
        />
      </div>

      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption3}
        </p>
        <img
          src="/Images/detailss/copyicon.svg"
          alt=".."
          onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption3)}
          style={{ cursor: 'pointer' }}
        />
      </div>


    </div>

  );
};

export default ContentLibraryDetails;