import React, { useRef, useState } from "react";
import ResponsiveAppBar from "../../Components/Navbar/Appbar";
import Lines from "../../assets/div1lines.svg";
import DigitalCourses from "../../assets/Div2Image.svg";
import InstagramMastery from "../../assets/div2box1.svg";
import TwitterMastery from "../../assets/div2box2.svg";
import image1 from "../../assets/5.png";
import image2 from "../../assets/3.png";
import image3 from "../../assets/Rectangle 5330.png";
import image4 from "../../assets/Rectangle 5332.png";
import image5 from "../../assets/2.png";
import hoverBoxArrow from "../../assets/hoverBoxTop.svg";
import div3 from "../../assets/cards.png";
import CardNo from "../../assets/cardNo.svg";
import Final from "../../assets/FinalDiv.svg";
import "./landingPage.css";
import NewFooter from "../../Components/NewFooter/NewFooter";
import SettingsIcon from "@mui/icons-material/Settings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import bottomLine from "../../assets/bottomLineContext.svg"
import topLine from "../../assets/ToplineContent.svg"
import star2 from "../../assets/Star 2.png";
import Star4 from "../../assets/Star 4.png";


const LandingPage = () => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenToggle = () => {
    const videoContainer = videoRef.current.parentNode;
    if (!document.fullscreenElement) {
      videoContainer
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          console.log("FullScreen")
        })
        .catch((err) => {
          console.error("Failed to enter fullscreen:", err);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Failed to exit fullscreen:", err);
        });
    }
  };


  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

    e.target.style.setProperty("--volume-fill", `${newVolume * 100}%`);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  return (
    <div className="landingPage">
      <ResponsiveAppBar />
      <div className="landingPage__topgradient">
        <div className="blurnessDiv1"></div>
        <div className="Landingpage-div1Bottom">
          <div className="Blurness"></div>
          <div className="GlobalDiv1">
            <div className="globaldiv1Top">
              <div className="helowediv">
                <p className="helowe">👋 Hello! We are experts!</p>
              </div>
              <p className="globalHeading">
                your{" "}
                <span className="globalHeadingBlue">
                  {" "}
                  digital <br className="breakline" /> marketing{" "}
                </span>
                experts
              </p>
              <p className="globalPara">
                Month to Month Payments, No Setup Fee, Pre-Trained Virtual
                Assistants
              </p>
            </div>
            <div className="div1leftButtons">
              <button className="startNowButton">Book a Call</button>
              <button className="LearnMoreButton">Sign Up</button>
            </div>

            <div className="globaldivright">
              <div className="video-player-containerss">
                <video
                  ref={videoRef}
                  src="https://www.w3schools.com/html/movie.mp4"
                  className="video-element"
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleLoadedMetadata}
                  onEnded={() => setIsPlaying(false)}
                ></video>

                <div className="controlss" style={{ bottom: isFullscreen ? "0" : "-40px",}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "20px",
                     
                    }}
                  >
                    <div
                      className="video-play-and-stop-the-btn--main----side"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="video-play-and-stop-the-btn">
                        <div className="button-and-time">
                          <button
                            className="playButton "
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={togglePlayPause}
                          >
                            {isPlaying ? (
                              <PauseIcon className="setting" />
                            ) : (
                              <PlayArrowIcon className="setting" />
                            )}
                          </button>
                          <p className="TimePara">
                            {formatTime(currentTime)}/{formatTime(duration)}
                          </p>
                        </div>
                        <div className="Other-video-icons">
                          <input
                            type="range"
                            className="volume-bar"
                            min="0"
                            max="1"
                            step="0.1"
                            value={volume}
                            onChange={handleVolumeChange}
                          />

                          <button
                            className="fullscreen-btn"
                            onClick={handleFullscreenToggle}
                          >
                            {isFullscreen ? (
                              <FullscreenExitIcon className="setting" />
                            ) : (
                              <FullscreenIcon className="setting" />
                            )}
                          </button>
                          <button className="settings-btn">
                            <SettingsIcon className="setting" />
                          </button>
                        </div>
                      </div>

                      <div
                        className="progress-bar-container"
                        style={{
                          width: "97%",
                          height: "6px",
                          backgroundColor: "gray",
                          left: "3px",
                          position: "relative",
                          borderRadius: "7px",
                        }}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${(currentTime / duration) * 100}%`,
                            height: "100%",
                            backgroundColor: "white",
                            borderRadius: "7px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrappingDiv2">
        <div className="whitelinesdiv">
      <div className="TopLine">
      <div className="scrolling-container">
        <p className="TopLinePara">INSTAGRAM COURSES </p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">content libRARY</p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">Digital academy</p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">TWITTER COURSES</p>
        <img src={star2} alt="star" />

      <p className="TopLinePara">  PRICING </p>
      <img src={star2} alt="star" />
      <p className="TopLinePara">INSTAGRAM COURSES </p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">content libRARY</p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">Digital academy</p>
        <img src={star2} alt="star" />

        <p className="TopLinePara">TWITTER COURSES</p>
</div>
      </div>
      <div className="bottomLine">
      <div className="scrolling-container">
      <p className="TopLinePara">INSTAGRAM COURSES </p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">content libRARY</p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">Digital academy</p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">TWITTER COURSES</p>
        <img src={Star4} alt="star" />

      <p className="TopLinePara">  PRICING </p>
      <img src={Star4} alt="star" />
      <p className="TopLinePara">INSTAGRAM COURSES </p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">content libRARY</p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">Digital academy</p>
        <img src={Star4} alt="star" />

        <p className="TopLinePara">TWITTER COURSES</p>
        <img src={Star4} alt="star" />

      <p className="TopLinePara">  PRICING </p>


    </div>
      </div>


        </div>
        <div className="div2">
        <div className="blurdiv2"></div>
        <div className="div2TopHeading">
          <p className="Div2Heading">
            digital <span className="div2courses">courses </span>
          </p>
          <p className="div2topPara">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
            <br className="breakline1" />
            incididunt ut labore et dolore magna aliquaenim ad minim veniam,
            quis.
          </p>
        </div>
        <div className="div2below">
          <div className="div2Belowleft">
            <div className="div2Box">
              <img
                className="InstaGramMastryImage"
                src={InstagramMastery}
                alt="Instagram Mastery"
              />
              <div className="div2Belowbox">
                <p className="Div2Box1Heading">Instagram Mastery</p>
                <p className="div2box1para">
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
                <button className="Buydiv2BoxButton">Buy</button>
              </div>
            </div>
            <div className="div2Box">
              <img
                className="InstaGramMastryImage"
                src={TwitterMastery}
                alt="Twitter Mastery"
              />
              <div className="div2Belowbox">
                <p className="Div2Box1Heading">Twitter Mastery</p>
                <p className="div2box1para">
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
                <button className="Buydiv2BoxButton">Buy</button>
              </div>
            </div>
          </div>
          <div className="div2BelowRight">
            <img src={DigitalCourses} alt="Digital Courses" />
          </div>
        </div>
      </div>
      </div>
      </div>
      
      <div className="div3">
        <div className="div3Top">
          <div className="div3Topleft">
            <p className="Div2Heading">
              content <span className="div2courses">library </span>{" "}
            </p>
            <p className="contentpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              <br className="breakLine3" /> eiusmod tempor incididunt ut labore
              et dolore magna aliquaenim.
            </p>
          </div>
          <button className="Viewdiv3Button">View All</button>
        </div>
        <div className="div3below">
          <div class="image-container bg-image-1">
            <div class="simpleImage">
              <img style={{ borderRadius: "20px" }} src={image1} alt="Simple Image" />
            </div>

            <div class="image-card">
              <div class="hover-content">
                <div className="arrowDiv">
                  <img className="arrow" src={hoverBoxArrow} alt="arrow" />
                </div>
                <div className="hoverbelowDiv">
                  <p className="hovrBelowpara1">LOREM IPSUM</p>
                  <p className="hoverBelowpara2">
                    Lorem Ipsum is simply dummy text the printing and provide
                    best visa ever
                  </p>
                  <button className="DownLoadButton">Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="image-container  bg-image-2">
            <div class="simpleImage">
              <img style={{ borderRadius: "20px" }} src={image5} alt="Simple Image" />
            </div>

            <div class="image-card">
              <div class="hover-content">
                <div className="arrowDiv">
                  <img className="arrow" src={hoverBoxArrow} alt="arrow" />
                </div>
                <div className="hoverbelowDiv">
                  <p className="hovrBelowpara1">LOREM IPSUM</p>
                  <p className="hoverBelowpara2">
                    Lorem Ipsum is simply dummy text the printing and provide
                    best visa ever
                  </p>
                  <button className="DownLoadButton">Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="image-container  bg-image-3">
            <div class="simpleImage">
              <img style={{ borderRadius: "20px" }} src={image2} alt="Simple Image" />
            </div>

            <div class="image-card">
              <div class="hover-content">
                <div className="arrowDiv">
                  <img className="arrow" src={hoverBoxArrow} alt="arrow" />
                </div>
                <div className="hoverbelowDiv">
                  <p className="hovrBelowpara1">LOREM IPSUM</p>
                  <p className="hoverBelowpara2">
                    Lorem Ipsum is simply dummy text the printing and provide
                    best visa ever
                  </p>
                  <button className="DownLoadButton">Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="image-container bg-image-4">
            <div class="simpleImage">
              <img style={{ borderRadius: "20px" }} src={image3} alt="Simple Image" />
            </div>

            <div class="image-card">
              <div class="hover-content">
                <div className="arrowDiv">
                  <img className="arrow" src={hoverBoxArrow} alt="arrow" />
                </div>
                <div className="hoverbelowDiv">
                  <p className="hovrBelowpara1">LOREM IPSUM</p>
                  <p className="hoverBelowpara2">
                    Lorem Ipsum is simply dummy text the printing and provide
                    best visa ever
                  </p>
                  <button className="DownLoadButton">Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="image-container bg-image-5">
            <div class="simpleImage">
              <img style={{ borderRadius: "20px" }} src={image4} alt="Simple Image" />
            </div>

            <div class="image-card">
              <div class="hover-content">
                <div className="arrowDiv">
                  <img className="arrow" src={hoverBoxArrow} alt="arrow" />
                </div>
                <div className="hoverbelowDiv">
                  <p className="hovrBelowpara1">LOREM IPSUM</p>
                  <p className="hoverBelowpara2">
                    Lorem Ipsum is simply dummy text the printing and provide
                    best visa ever
                  </p>
                  <button className="DownLoadButton">Download</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<div className="wrappingDiv">
      <div className="div4">
        <div className="div4Top">
          <p className="Div2Heading">
            skainet <span className="div2courses">payment </span> gateway
          </p>
          <p className="div4topPara">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            <br className="breakline4" />
            eiusmod tempor incididunt ut labore et dolore magna aliquaenim.
          </p>
        </div>
        <div className="div4below">
          <div className="blurness-center"></div>
          <div className="div4Form">
            <div className="InputsDiv4">
              <div className="Input1">
                <p className="InputFormpara">Name on Card</p>
                <input className="NameInput" placeholder="Enter Name" />
              </div>
              <div class="Input1">
                <p className="InputFormpara">Card Number</p>
                <div class="input-wrapper">
                  <input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    id="cardNumber"
                    class="CardInput"
                    placeholder="1234 1234 1234 1234"
                  />
                  <img src={CardNo} alt="Card Icon" class="input-icon" />
                </div>
              </div>
              <div className="formRow">
                <div className="Input2">
                  <p className="InputFormpara">Expiry Date</p>
                  <input
                    className="NameInput"
                    type="text"
                    placeholder="Enter Date"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </div>
                <div className="Input2">
                  <p className="InputFormpara">CVC</p>
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    className="NameInput"
                    placeholder="Enter CVC"
                  />
                </div>
              </div>
              <div class="Input1">
                <p className="InputFormpara">Country</p>
                <select id="country" class="custom-dropdown">
                  <option style={{width:"100%"}} value="none">Select Country</option>
                  <option style={{width:"100%"}}  value="usa">United States</option>
                  <option style={{width:"100%"}} value="uk">United Kingdom</option>
                  <option style={{width:"100%"}} value="canada">Canada</option>
                  <option style={{width:"100%"}} value="australia">Australia</option>
                </select>
              </div>

              <div className="Input1">
                <p className="InputFormpara">ZIP</p>
                <input
                  className="NameInput"
                  placeholder="Enter Zip"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
            </div>
            <button className="NexTFormButton">Next</button>
          </div>
          <div className="div4right">
            <img  src={div3} alt="CardNo" />
          </div>
        </div>
      </div>

      <div className="finalDiv">
        <div className="BlurnessFinal"></div>
        <div className="finalheading">
          <p className="Div2Heading">
            lorem <span className="div2courses">ispum </span>{" "}
          </p>
        </div>
        <div className="finalbottom">
          <div className="FINALBOTTOMLEFT">
            <div className="FinalBox1">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">instagram mastery</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>

            <div className="FinalBox">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">Twitter mastery</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>

            <div className="FinalBox">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">Marketing COntent</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>
          </div>
          <div className="finalBottomright">
            <img src={Final} alt="Image" />
          </div>
        </div>
      </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default LandingPage;
