import React, { useState } from "react";
import "./SignUp.css";
import { useDispatch } from "react-redux";
import { signup } from "../../services/redux/middleware/signup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const SignUp = () => {

  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [refferalLink, setReferral] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [showPassword1, setShowPassword1] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSignUp = async () => {

    if (!fullName || !email || !phoneNumber || !password || !password2) {
      toast.error("Enter all text fields");
      return;
    }

    if (fullName.length < 3 || fullName.length > 20) {
      toast.error("Full name must be between 3 and 20 characters");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }


    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(password)) {
      toast.error("Password must be at least 8 characters, include 1 capital letter, 1 numeric value, and 1 special character");
      return;
    }


    if (password !== password2) {
      toast.error("Passwords do not match");
      return;
    }

    if (phoneNumber.length < 6 || phoneNumber.length > 16 || !/^\+?[0-9]+$/.test(phoneNumber)) {
      toast.error("Phone Number must be between 6 and 16 digits");
      return;
    }
    

    setIsLoading(true);
    const data = { fullName, email, password, phoneNumber, refferalLink };
    try {
      const result = await dispatch(signup(data));
      console.log(data);
      console.log(result);

      if (result?.payload?.status === 201) {
        setIsLoading(false);
        navigate(`/AccountVerification/${email}`);
      } else if (result?.payload?.status === 400) {
        setIsLoading(false);
        setTimeout(() => {
          toast.error("User Already Exists");
        }, 100);

      } else {
        setIsLoading(false);
        setTimeout(() => {
          toast.error("Referral Code Invalid");
        }, 100);

      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (

    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMain">
          <img className="SignUpImg" src="/Images/SignupImg/hero.png" alt="" />
          <div className="SignUpMain11" >
            <div className="SignUpMain1">
              <div className="SignUpMain1_1">
                <img className="SignUpMain1_1icon" src="/Images/SignupImg/icon.svg" alt="...." />

                <p className="SignUpMain1_1ptag">Register your Account</p>
              </div>

              <div className="SignUpMain1_2">
                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Full Name</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/name.svg" alt="...." className="image-icon" />
                    <input
                      type="text"
                      maxLength="20" 
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Email Address</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon" />
                    <input
                      type="text"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="skainetuser@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility1}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword1 ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div>


                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Confirm Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility2}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword2 ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Phone Number</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon" />
                    <input
                      type="tel"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Phone Number"
                      value={phoneNumber}
                      pattern="[0-9]*"
                      maxLength="16"  
                      onChange={(e) => {
                        const value = e.target.value;                     
                        if (/^\+?[0-9]*$/.test(value)) {
                          setPhoneNumber(value);
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Referral Code (Optional)</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon" />
                    <input
                      type="text"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Code"
                      value={refferalLink}
                      maxLength={6}
                      onChange={(e) => setReferral(e.target.value)}
                    />
                  </div>
                </div>

              </div>

              <button style={{ marginBottom: "17px" }} className="SignUpMain1_2Btn" onClick={handleSignUp}>Sign Up</button>
              <p className="alreadysignup">Already have an account?<span className="alreadysignup2" onClick={() => navigate("/")}> Sign in</span></p>

            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />

        </div>)}</>
  );
};

export default SignUp;
