import React, { useState } from "react";
import "./ResetPassword1.css";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../services/redux/middleware/forgetPassword";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const ResetPassword1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);


  const handleResetPassword = async () => {
    console.log("email", email);
    if (!email) {
      toast("Enter Email");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    const data = { email: email };
    const result = await dispatch(forgetPassword(data));
    console.log(result)
    if (result?.payload?.status === 200) {
      setIsLoading(false);
      navigate(`/resetverifycode/${email}`);

    } else if (result?.payload?.status === 404) {
      setIsLoading(false);
      setTimeout(() => {
        toast.error("User Not Found");
      }, 100)
    } else {
      setIsLoading(false);
      setTimeout(() => {
        toast.error("Error");
      }, 100)
    }

  }

  const [email, setEmail] = useState("");

  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMainNext">
          <img className="SignUpImgNext" src="/Images/SignupImg/hero.png" alt="" />
          <div className="SignUpMain11Next">
            <div className="SignUpMain1Next">
              <div className="SignUpMain1_1Next">
                <img src="/Images/SignupImg/icon.svg" alt="...." />
                <p className="SignUpMain1_1ptagNext">Reset Password</p>
              </div>

              <div className="SignUpMain1_2Next">
                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Email Address</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon"/>
                    <input
                      type="text"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <button className="SignUpMain1_2BtnNext" onClick={handleResetPassword}>Reset Password</button>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />
        </div>)}</>
  );
};

export default ResetPassword1;
