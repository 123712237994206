import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getCourse = createAsyncThunk("getCourse", async (id) => {
  try {
    console.log("Fetching course with ID:", id);

   
    const res = await api.get(`${API_URL}/course/get-course/${id}`);

    console.log("API Response:", res);

    
    if (res.data) {
      return {
        status: res.status,
        data: res.data,  
      };
    } else {
      console.error("Malformed data:", res.data);
      return {
        status: 404,
        message: "Course data not found",
      };
    }
  } catch (error) {
    console.error("Error fetching course:", error);
    return {
      message: error?.response?.data?.error || "Unknown error",
      status: error?.response?.status || 500,
    };
  }
});

